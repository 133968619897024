body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .grid-item { width: 25%; } */

* { 
  box-sizing: border-box; 
}

/* force scrollbar */
html { 
  overflow-y: scroll; 
}

body { 
  margin: 0px;
  font-family: sans-serif; 
}

/* ---- grid ---- */

.grid {
/* background: #DDD; */
margin: 5px;
}

/* clear fix */
.grid:after {
content: '';
display: block;
clear: both;
}

/* ---- .grid-item ---- */

.grid-item {
width: 33.333%;
padding: 5px;
}

.grid-item {
float: left;
}

.grid-item img {
display: block;
max-width: 100%;
}
